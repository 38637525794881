import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.css'; 
import SwiperCore from 'swiper';

import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/main.png';
import playstore from '../images/playstore.png'
import women from '../images/screnn.png'
import screen from '../images/screen.png'
import young from '../images/youngwome.png'
import clock from '../images/clock.png'




SwiperCore.use([]);


const Hero = () => {
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                            {/* We build digital solutions to help businesses scale */}
                            We develop application with, the most efficient technology                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Empowering businesses with innovative solutions. 
                            The goal of our company is not necessarily to have a large client base but to maintain a customer-friendly environment.</div>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                            {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"> */}
    <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
    <img src={playstore} alt="Play Store" className="w-6 h-6" />
    {/* <img src="https://w7.pngwing.com/pngs/859/487/png-transparent-google-play-computer-icons-android-google-text-label-logo.png" alt="Play Store" className="w-6 h-6" /> */}
    {/* <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
    </svg> */}
</a>

                            </div>
                            
                        </div>
                        
                     <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Swiper
                pagination={{ clickable: true }} // Enables pagination
                autoplay={{ delay: 3000 }} // Automatic slide change every 3 seconds
                loop={true} // Enable looping
                className="mySwiper"
                style={{ width: '450px', height: 'auto' }} // Set width for Swiper
            >
                <SwiperSlide>
                    <img 
                        alt="screen img" 
                        className="rounded-t duration-1000" 
                        style={{ width: '100%', height: 'auto' }} // Responsive image
                        src={screen} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img 
                        alt="women img" 
                        className="rounded-t duration-1000" 
                        style={{ width: '100%', height: 'auto' }} // Responsive image
                        src={women} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img 
                        alt="hero img" 
                        className="rounded-t duration-1000" 
                        style={{ width: '100%', height: 'auto' }} // Responsive image
                        src={heroImg} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img 
                        alt="hero img" 
                        className="rounded-t duration-1000" 
                        style={{ width: '100%', height: 'auto' }} // Responsive image
                        src={young} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img 
                        alt="hero img" 
                        className="rounded-t duration-1000" 
                        style={{ width: '100%', height: 'auto' }} // Responsive image
                        src={clock} 
                    />
                </SwiperSlide>

            </Swiper>
        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;